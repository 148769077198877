import React from 'react';
import Layout from '../components/layout';
import {graphql} from 'gatsby';
import PopularPosts from '../components/popularPosts';
import {AboutUs} from '@cg-squad/ui-components';
import {Basic} from '../components/SEO';
import {metadata} from '../../config';
import {Helmet} from 'react-helmet';

function AboutUsPage (props) {
  const seo = {
    title: `About Us | ${metadata.defaultTitle}`,
    description: `${metadata.description} | About Us`
  };
  return (
    <Layout relativePath="/">
      <Basic seo={seo}/>
      <Helmet>
        <link rel="canonical" href={`${metadata.url}/about-us`}/>
      </Helmet>
      <main className="wrapper main-data-container" data-datocms-noindex>
        <div className={'w-full flex'}>
          <AboutUs page={props.data.page} title={'About Age Times'}
                   allAuthors={props.data.allAuthors}/>
          <PopularPosts articles={props.data.relatedArticles}/>
        </div>
      </main>
    </Layout>
  );
}

export const query = graphql`
    query Authors($domain: String) {
        page: datoCmsPage(slug: {eq: "about-us"}, website: {elemMatch: {name: {eq: "at"}}}, domains: {elemMatch: {name: {eq: $domain}}}) {
            content
            slug
        }
        allAuthors: allDatoCmsAuthor(filter: {website: {elemMatch: {name: {eq: "at"}}}, domains: {elemMatch: {name: {eq: $domain}}}}) {
            nodes {
                name
                slug
                id: originalId
                profilePhoto {
                    url
                }
                bio
                linkedin
                email
                twitter
                portfolio
            }
        }
        relatedArticles: allDatoCmsArticle(
            filter: {website: {elemMatch: {name: {eq: "at"}}}, domains: {elemMatch: {name: {eq: $domain}}}}
            limit: 5
            skip: 1
        ) {
            nodes {
                ...ArticleFieldsPluginWithPortraitImage
            }
        }
    }
`;

export default AboutUsPage;
